import Button from '@atoms/Button'
import Box from '@atoms/Box'

export default function BannerBlock ({ children, buttonLabel, buttonDisabled, onButtonClick, large }) {
  let className = 'w-full'

  if (buttonLabel) {
    className += ' pb-0'
  }

  className += large ? ' max-w-168 md:w-168' : ' max-w-96 md:w-96'

  return (
    <Box
      className={className}
      overlay
    >
      {children}

      {buttonLabel && (
      <div className="flex justify-center translate-y-1/2">
        <Button
          onClick={onButtonClick}
          disabled={buttonDisabled}
          className="px-10 py-2"
          primary
        >
          {buttonLabel}
        </Button>
      </div>
      )}
    </Box>
  )
}
