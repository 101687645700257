import { NextSeo } from 'next-seo'
import { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrophy, faAxeBattle, faSwords, faFlame } from '@fortawesome/pro-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { setAuthUser } from '../store'
import BannerBlock from 'components/layout/BannerBlock'
import ButtonLink from '@atoms/ButtonLink'
import { faSword } from '@fortawesome/pro-duotone-svg-icons'

export default function Home () {
  const dispatch = useDispatch()
  const justLoggedOut = useSelector(state => state.main.auth.justLoggedOut)
  const mobileMenuIsOpen = useSelector(state => state.main.mobileMenu.isOpen)

  useEffect(() => {
    if (justLoggedOut) {
      dispatch(setAuthUser(false))
    }
  }, [])

  return (
    <>
      <NextSeo
        title="Tavern Squad"
      />
      <section className="relative flex w-full pb-6 h-banner min-h-banner lg:h-screen lg:min-h-screen">
        <div className={'absolute top-0 left-0 right-0 z-0 h-screen bg-center bg-cover min-h-banner lg:min-h-screen bg-background-fallback bg-banner'}></div>
        <div className={`container flex flex-col items-center justify-center h-full px-6 mx-auto z-10  ${mobileMenuIsOpen ? 'hidden' : ''}`}>
          <img
            src={'/ale-and-axes-full-logo.png'}
            alt="Tavern Squad logo"
            className="h-48 mb-6"
          />
          <BannerBlock>
            <div className="text-left space-y-4">
              <ul className="space-y-2">
                <li className="flex items-center">
                  <span className="flex items-center justify-center p-1 mr-4 border rounded-full w-9 h-9 bg-tertiary border-primary">
                    <FontAwesomeIcon
                      icon={faSwords}
                      className="w-4 h-4"
                    />
                  </span>
                  {' '}
                  Playable NFT RPG browser game
                </li>
                <li className="flex items-center">
                  <span className="flex items-center justify-center p-1 mr-4 border rounded-full w-9 h-9 bg-tertiary border-primary">
                    <FontAwesomeIcon
                      icon={faFlame}
                      className="w-4 h-4"
                    />
                  </span>
                  {' '}
                  10,000 unique adventurers
                </li>
                <li className="flex items-center">
                  <span className="flex items-center justify-center p-1 mr-4 border rounded-full w-9 h-9 bg-tertiary border-primary">
                    <FontAwesomeIcon
                      icon={faSword}
                      className="w-4 h-4"
                    />
                  </span>
                  {' '}
                  Dungeon raids
                </li>
                <li className="flex items-center">
                  <span className="flex items-center justify-center p-1 mr-4 border rounded-full w-9 h-9 bg-tertiary border-primary">
                    <FontAwesomeIcon
                      icon={faAxeBattle}
                      className="w-4 h-4"
                    />
                  </span>
                  {' '}
                  Account/level system
                </li>
                <li className="flex items-center">
                  <span className="flex items-center justify-center p-1 mr-4 border rounded-full w-9 h-9 bg-tertiary border-primary">
                    <FontAwesomeIcon
                      icon={faTrophy}
                      className="w-4 h-4"
                    />
                  </span>
                  {' '}
                  Achievements/Quests
                </li>
              </ul>

              <p className="w-full flex flex-wrap md:flex-nowrap md:justify-between">
                <ButtonLink
                  href="/tavern"
                  className="w-full mb-4 font-sans text-white md:mb-0 md:w-auto"
                  raids
                >
                  Start your journey
                </ButtonLink>
                <ButtonLink
                  href="https://www.jpg.store/collection/tavernsquad"
                  className="w-full font-sans bg-raids-light hover:bg-tertiary-hover md:w-auto"
                  raids
                >
                  Buy on jpg.store
                </ButtonLink>
              </p>
            </div>
          </BannerBlock>
        </div>
      </section>
    </>
  )
}
