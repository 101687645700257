import PropTypes from 'prop-types'

export default function Box ({ className, overlay, children }) {
  let classes = 'p-5 rounded bg-dark border border-primary'

  if (overlay) {
    classes += ' bg-tertiary bg-opacity-80'
  }

  return (
    <div className={`${classes} ${className}`}>
      {children}
    </div>
  )
}

Box.propTypes = {
  children: PropTypes.node.isRequired,
  overlay: PropTypes.bool,
  className: PropTypes.any
}

Box.defaultProps = {
  overlay: false,
  className: ''
}
